// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-[id]-js": () => import("./../../../src/pages/support/[id].js" /* webpackChunkName: "component---src-pages-support-[id]-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-and-security-[id]-js": () => import("./../../../src/pages/terms-and-security/[id].js" /* webpackChunkName: "component---src-pages-terms-and-security-[id]-js" */),
  "component---src-pages-test-topic-id-js": () => import("./../../../src/pages/test/[topicId].js" /* webpackChunkName: "component---src-pages-test-topic-id-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-pages-topics-topic-id-category-id-lesson-id-js": () => import("./../../../src/pages/topics/[topicId]/[categoryId]/[lessonId].js" /* webpackChunkName: "component---src-pages-topics-topic-id-category-id-lesson-id-js" */),
  "component---src-pages-topics-topic-id-js": () => import("./../../../src/pages/topics/[topicId].js" /* webpackChunkName: "component---src-pages-topics-topic-id-js" */),
  "component---src-template-blog-blog-detail-wrapper-js": () => import("./../../../src/template/blog/BlogDetailWrapper.js" /* webpackChunkName: "component---src-template-blog-blog-detail-wrapper-js" */),
  "component---src-template-blog-tag-tag-detail-wrapper-js": () => import("./../../../src/template/blog/tag/TagDetailWrapper.js" /* webpackChunkName: "component---src-template-blog-tag-tag-detail-wrapper-js" */)
}

